





























































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import {
  DataDictionaryDtoPagedResultDto,
  FeedbackDto,
  FundDto,
  FundDtoPagedResultDto,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";

@Component({
  name: "FeedbackDtoList",
  components: {
    PagedTableView,
    AbSelect,
    ExportButton,
  },
  filters: {
    formatType(type: string, typeList: any[]) {
      let result = "";
      typeList.forEach((item) => {
        if (item.id == type) {
          result = item.displayName;
          return;
        }
      });
      return result;
    },
  },
})
export default class FeedbackDtoList extends Vue {
  feedBackType: any = [];
  fundList: FundDto[] = [];
  queryForm = {};

  created() {
    this.fetchDataDictionary();
    this.fetchFundList();
  }

  async fetchFundList() {
    await api.fund.getAll({}).then((res: FundDtoPagedResultDto) => {
      this.fundList = res.items ?? [];
    });
  }

  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({ key: "FeedBackType", maxResultCount: 1000 })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.feedBackType = res.items;
      });
  }

  // 获取表数据
  fetchData(params: any) {
    return api.feedback.getAll(params);
  }

  // 回复
  handleFeedback(row: FeedbackDto) {
    this.$router.push({
      name: "feedbackReply",
      params: { feedbackId: row.id!.toString() },
    });
  }

  handleSwitchShowPortal(row: FeedbackDto) {
    api.feedback.showPortal({ body: { id: row.id } }).then((res) => {
      row.isShowPortal = !row.isShowPortal;
      this.$message({ type: "success", message: "切换完成" });
    });
  }

  // 删除
  async handleDelete(row: FeedbackDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.feedback.delete({ id: row.id }).then((res) => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    });
  }
}
